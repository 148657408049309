import axios from "axios"
import { useState } from "react"
import { Link } from "react-router-dom"
import { AppBar, Button, TextInput, Toolbar } from "react95"

export const Bar = () => {



    return(
        <AppBar>
            <Toolbar style={{ justifyContent: 'space-between' }} className="container">
                    <Link to={"/"}><Button>Home</Button></Link>

                    <Link to={"/search"}><Button>Search</Button></Link>

            </Toolbar>

        </AppBar>
    )
}
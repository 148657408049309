import { Bar } from "./components/Bar";

import { createGlobalStyle, ThemeProvider } from 'styled-components';

/* Pick a theme of your choice */
import original from 'react95/dist/themes/original';

/* Original Windows95 font (optional) */
import ms_sans_serif from 'react95/dist/fonts/ms_sans_serif.woff2';
import ms_sans_serif_bold from 'react95/dist/fonts/ms_sans_serif_bold.woff2';
import { MenuList, MenuListItem, Separator, styleReset } from 'react95';
import { Route, Routes } from "react-router-dom";
import { Home } from "./Pages/Home";
import { User } from "./Pages/User";
import { Search } from "./Pages/Search";


const GlobalStyles = createGlobalStyle`
  ${styleReset}
  @font-face {
    font-family: 'ms_sans_serif';
    src: url('${ms_sans_serif}') format('woff2');
    font-weight: 400;
    font-style: normal
  }
  @font-face {
    font-family: 'ms_sans_serif';
    src: url('${ms_sans_serif_bold}') format('woff2');
    font-weight: bold;
    font-style: normal
  }
  body, input, select, textarea {
    font-family: 'ms_sans_serif';
  }
`;

function App() {
  return (
    <div className="App">
    <GlobalStyles />
    <ThemeProvider theme={original}>

      <Bar />

      <Routes>
        <Route path="/" element={<Home className="page" />}/>
        <Route path="/user/:id" element={<User className="page"/>} />
        <Route path="/search" element={<Search className="page"/>} />

      </Routes>




      </ThemeProvider>

    </div>
  );
}

export default App;

import moment from "moment/moment"
import { Anchor, Hourglass, Window, WindowContent, WindowHeader } from "react95"

export const UserInfo = (props) => {

    let id = props.id
    let data = props.data


    return(
        <Window className="fullSize">
            <WindowHeader>User.exe</WindowHeader>
            <WindowContent>

            {data === undefined ? (
                <Hourglass size={32} style={{ margin: 20 }} />
            ) : (
                <div className="row">
                    <div className="col-4">
                    <img src={data["twitterPfpUrl"]} className="image" />

                    </div>
                    <div className="col-8">
                    <h1 className="name">{data["twitterName"]} <Anchor target="_blank" href={"https://twitter.com/" + data["twitterUsername"]}>({data["twitterUsername"]})</Anchor> </h1>                    
                    
                    <p>Holders: {data["holderCount"]}</p>
                    <p>Holdings: {data["holdingCount"]}</p>
                    <p>Last Online: {moment(Number(data["lastOnline"])).fromNow()}</p>
                    <p>Last Message: {moment(Number(data["lastMessageTime"])).fromNow()}</p>


                    </div>
                </div>

            )}



            </WindowContent>
        </Window>
    )
}
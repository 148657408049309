import { Link } from "react-router-dom"
import { Anchor, Button, Window, WindowContent, WindowHeader } from "react95"

export const Home = () => {

    return(
        <div className="container page">

            <Window className="fullSize">
                <WindowHeader>FrenTech95.exe</WindowHeader>
                <WindowContent>
                    <p>Welcome to the free version of frentech95. Check out the <Anchor href="https://www.frentech95.com/" target="_blank">full terminal</Anchor> for more features.</p>
                    <p>Created by <Anchor href="https://twitter.com/0xsubmerged" target="_blank">0xsubmerged</Anchor></p>
                    <br></br>

                    <Link to={"/search"}><Button>Search for Users</Button></Link>
                    <br></br>
                    <br></br>


                    <Window>
                        <WindowHeader>Full_Version.exe</WindowHeader>
                        <WindowContent>

                            <div className="row">
                                <div className="col-lg-4">
                                    <h2>📈 Trade</h2>
                                    <img className="image1" src="trade.png"/>
                                </div>
                                <div className="col-lg-4">
                                    <h2>💬 Chat</h2>
                                    <img className="image1" src="chat.png"/>
                                </div>
                                <div className="col-lg-4">
                                    <h2>Essential tools</h2>
                                    <img className="image1" src="33.png"/>
                                </div>
                                <br></br>
                                <br></br>

                                <a target="_blank" href="https://www.frentech95.com/"><Button>Check it out!</Button></a>

                            </div>


                        </WindowContent>
                    </Window>





                </WindowContent>

            </Window>



        </div>
    )
}
import { useParams } from "react-router-dom";
import { Window, WindowContent, WindowHeader } from "react95";
import { UserInfo } from "../components/UserInfo";
import { ChartWindow } from "../components/ChartWindow";
import { useEffect, useState } from "react";
import axios from "axios";

export const User = () => {

    let { id } = useParams();
    const [userData, setUserData] = useState()

    // need to display charts - x
    // need to display user data 
    // need to have a search bar

    useEffect(() => {
        axios({
            method: 'get',
            url: "https://prod-api.kosetto.com/users/" + id
        }).then(response => {
            console.log(response.data)
            setUserData(response.data)
            //setLoadingChart(false)
        }).catch(error => {
            console.log(error)
            //setLoadingChart(false)
        })
    }, [id])


    return(
        <div className="page container">


            <div className="row">
                <div className="col-md-4">
                <UserInfo data={userData} id={id} />

                </div>
                <br></br>
                <br></br>

                <div className="col-md-8">
                <ChartWindow id={id} />

                </div>
            </div>



        </div>
    )
}
import axios from "axios"
import { useEffect, useState } from "react"
import { TextInput, Window, WindowContent, WindowHeader } from "react95"
import { SearchResult } from "../components/SearchResults"

export const Search = () => {

    const [user, setUser] = useState("")
    const handleUserChange = (event) => {
        const {value,name} = event.target
        setUser(value)
    }

    const [searchedUser, setSearchedUser] = useState({})

    useEffect(() => {
        axios({
            method: 'get',
            url: "https://frenstech-0d10a15ae100.herokuapp.com/search/" + user
        }).then(response => {
            console.log(response.data)
            setSearchedUser(response.data)
        }).catch(error => {
            console.log(error)
        })
    },[user])


    return(
        <div className="page container">
            <Window className="fullSize">
                <WindowHeader>Search.exe</WindowHeader>
                <WindowContent>
                    <TextInput
                    value={user}          
                    placeholder='Search ...'
                    onChange={handleUserChange}
                    /> 


                    <SearchResult setSelectedUser={user} data={searchedUser}  />          


                </WindowContent>
            </Window>



        </div>
    )
}
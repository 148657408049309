import { useDebugValue } from "react"
import { Link } from "react-router-dom"
import { Anchor, Button } from "react95"

export const SearchResult = (props) => {

    let data = props.data


    if(data[0] != undefined) {
        return(
            <div>   
                <br></br>

                {data.map(user => {

                    return(
                        <li className="listItem">
                            <img src={user["twitterPfpUrl"]} width="20em" className="imageSmol"/>
                            <Anchor href={"https://twitter.com/" + user["twitterUsername"]} target="_blank">
                                {user["twitterUsername"]} ({user["twitterUsername"]})
                            </Anchor>

                            <Link to={"/user/" + user["address"]}>
                            <Button className="miniButton" size="sm">Select</Button>

                            </Link>

                        </li>
                    )
                })}


            </div>
        )
    } else {
        return(
            <div>
                <br></br>
                <p>Search for something</p>
            </div>
        )
    }
    

}
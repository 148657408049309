import { Window, WindowContent, WindowHeader } from "react95"
import { ChartFromAddress } from "./ChartRender"
import { useEffect, useState } from "react"
import { API } from "../API"
import axios from "axios"

export const ChartWindow = (props) => {

    let id = props.id

    const [chartData, setChartData] = useState([])

    useEffect(() => {
        //setLoadingChart(true)
        axios({
            method: 'get',
            url: API + "trade_history_by_subject/" + id
        }).then(response => {
            console.log(response.data)
            setChartData(response.data)
            //setLoadingChart(false)
        }).catch(error => {
            console.log(error)
            //setLoadingChart(false)
        })
    
    },[id])

    
    return(
        <Window className="fullSize">
            <WindowHeader>Chart.exe</WindowHeader>
            <WindowContent>
                <ChartFromAddress id="container" data={chartData} />
            </WindowContent>
        </Window>
    )
}